body {
  p {
    margin-top: 0;
    margin-bottom: 1em;

    &:last-child {
      margin-bottom: 0;
    }
  }

  &, & > p, & > span {
    margin: 0;
    padding: 0;
    color: #363636;
    font-family: 'Ubuntu';
    font-size: 17px;
    line-height: 22px;
    font-weight: 300;
    max-width: 100vw;
    overflow-x: hidden;
  }
}

