/************************
         Header Inner
*************************/

.header-inner {
  overflow: hidden;
  position: relative;
  display: flex;
  flex-direction: column;

  &[data-image-global="false"] {
    display: none;
  }
}


//  In Header
.header-inner.in_header {
  &[data-size="tall"] {
    height: 550px;
    @media (max-width: 991px) {
      height: 350px;
    }
  }

  &[data-size="standard"] {
    height: 350px;
  }

  &[data-size="little"] {
    height: 150px;
  }

  .page-title {
    display: none;
  }

  .header-inner__image {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
    z-index: 5;
  }
}


//  In Content
.header-inner.in_content {
  width: 1000px !important;
  max-width: calc(100% - 30px);
  margin-left: auto;
  margin-right: auto;
  padding-top: 0 !important;
  margin-top: 65px;

  &[data-size="tall"] {
    .attachment-post-thumbnail {
      height: 550px;
      @media (max-width: 991px) {
        height: 350px;
      }
    }
  }

  &[data-size="standard"] {
    .attachment-post-thumbnail {
      height: 350px;
    }
  }

  &[data-size="little"] {
    .attachment-post-thumbnail {
      height: 150px;
    }
  }

  // If Not Image
  &[data-image="false"] {
    height: auto !important;

    & + .page-template {
      .internal-page-template {
        padding-top: 5px !important;
      }
    }

  }


  .header-inner__image {
    width: 100%;
    height: 100%;
    object-fit: cover;
    z-index: 5;
  }

  .page-title {
    padding-bottom: 10px;
  }

  .page-overlay, .blog-page-overlay {
    display: none;
  }

  &.ho-banner-image {
    padding-top: 155px;
  }

}


// In Header with Title
.header-inner.in_header_title {
  display: flex;
  align-items: center;

  &[data-size="tall"] {
    height: 550px;
    @media (max-width: 991px) {
      height: 350px;
    }
  }

  &[data-size="standard"] {
    height: 350px;
  }

  &[data-size="little"] {
    height: 150px;
  }

  .container {
    height: 100%;
    display: flex;
    align-items: center;
  }

  .page-title {
    width: 100%;
    display: block;
    position: relative;
    z-index: 15;
    color: #fff;
    line-height: 1;
  }

  .header-inner__image {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
    z-index: 5;
  }
}


// Overlays
.page-overlay, .blog-page-overlay {
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 6;
  width: 100%;
  height: 100%;
}


// Nav Style D
.nav_style_d {
  .header-inner {
    position: relative;

    &[data-size="little"] {
      .d_header_logo {
        display: none;
      }
    }
  }
}

.d_header_logo {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 15;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translateX(-50%) translateY(-50%);
  .img-wrap {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 15px;
    width: 85%;
    max-width: 450px !important;
    max-height: 250px !important;
  }

  img {
    padding: 15px;
    position: relative;
    z-index: 15;
    display: block;
    object-fit: contain;
    max-width: 100%;
    width: 100%;
    height: 100%;
    max-height: 220px;
  }

}