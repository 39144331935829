html {
  background-color: #fff;
}

body {
  background-color: #fff;

  &.wait {
    &, * {
      cursor: wait;
    }
  }
}

.page-template {
  background-color: #fff;
}

.site-template-wrapper {
  background-color: #fff;
  max-width: 100vw;
  overflow-x: hidden;
  width: 100%;
}

html {
  max-width: 100vw;
  overflow-x: hidden;
}

.hide, .hidden {
  display: none;
}

.wide {
  width: 100%;
  max-width: 100%;
}

// Default Page Template
.internal-page-template {
  background-color: #fff;
  padding: 65px 0;

  // Layout content-sidebar
  &[data-layout="content-sidebar"] {
    .page-row {
      flex-wrap: wrap;
      display: flex;
      flex-direction: row;
      justify-content: space-between;

      .content-col {
        width: calc(65% - 30px);
        padding-left: 15px;
      }

      .sidebar-col {
        padding-right: 15px;
        width: calc(35% - 30px);
      }
    }

    @media (max-width: 991px) {
      .page-row {
        .content-col {
          margin-left: auto;
          margin-right: auto;
          width: calc(100%);
          padding: 0 15px;
        }

        .sidebar-col {
          margin-left: auto;
          margin-right: auto;
          width: calc(100%);
          padding: 0 15px;
        }
      }
    }
  }

  // Layout sidebar-content
  &[data-layout="sidebar-content"] {
    .page-row {
      flex-wrap: wrap;
      display: flex;
      flex-direction: row-reverse;
      justify-content: space-between;
      .content-col {
        padding-right: 15px;
        width: calc(65% - 30px);
      }

      .sidebar-col {
        padding-left: 15px;
        width: calc(35% - 30px);
      }
    }

    @media (max-width: 991px) {
      .page-row {
        .content-col {
          margin-left: auto;
          margin-right: auto;
          width: calc(100%);
          padding: 0 15px;
        }

        .sidebar-col {
          width: calc(100%);
          padding: 0 15px;
        }
      }
    }

  }

  // Layout full-width-content
  &[data-layout="full-width-content"] {
    .page-row {
      justify-content: space-between;

      .left-col {
        margin-left: auto;
        margin-right: auto;
        width: 100%;
        padding: 0 15px;
      }

      .right-col {
        display: none;
      }
    }

    @media (max-width: 767px) {
      .page-row {
        .content-col {
          margin-left: auto;
          margin-right: auto;
          width: 100%;
        }

        .sidebar-col {
          margin-left: auto;
          margin-right: auto;
          width: 100%;
        }
      }
    }
  }

  .content-col {
    width: 100%;
  }

  .sidebar-col {
    width: 100%;

    .textwidget {
      text-align: center;
    }
  }

  .page-title {
    z-index: 10;
    display: block;
    margin-bottom: 0.35em;
  }
}

// Default Page Content
.default-page-content {
  padding: 30px 0;

  h1, h2, h3, h4, h5, h6 {
    margin-top: 25px;
    margin-bottom: 25px;
  }

  & > h1, h2, h3, h4, h5, h6 {
    &:first-child {
      margin-top: 0;
    }
  }

  ul, ol {
    margin-bottom: 20px;
    padding-inline-start: 40px;
    list-style: disc;
  }


  .contact-button-wrap {
    margin-top: 20px;
  }

}

