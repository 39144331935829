
/************************
        404 Error Styles
*************************/
.wrap-404 {
  padding-left: 20px;
  padding-right: 20px;
  padding-top: 180px;
  padding-bottom: 120px;
}

.wrap-404 .error-caption {
  font-size: 240px;
  font-family: "Montserrat", sans-serif;
  color: rgb(123, 123, 123);
  line-height: 1;
  text-align: center;
  font-weight: 500;
}

.wrap-404 .error-title {
  margin-bottom: 0;
  margin-top: 35px;
  line-height: 1;
  font-size: 50px;
  font-family: "Raleway", sans-serif;
  color: rgb(123, 123, 123);
  text-align: center;
  font-weight: 500;
}

.wrap-404 .error-subtitle {
  margin-top: 32px;
  margin-bottom: 0;
  font-size: 24px;
  font-family: "Raleway", sans-serif;
  color: rgb(123, 123, 123);
  line-height: 1.2;
  text-align: center;
}

.wrap-404 .btn-wrap {
  margin-top: 75px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}


@media (max-width: 1400px) {
  .wrap-404 {
    padding-top: 120px;
    padding-bottom: 100px;
  }

  .wrap-404 .error-caption {
    font-size: 140px;
  }

  .wrap-404 .error-title {
    font-size: 36px;
  }

  .wrap-404 .error-subtitle {

    margin-top: 26px;
    font-size: 18px;
  }

  .wrap-404 .btn-wrap {
    margin-top: 55px;
  }
}


@media (max-width: 767px) {

  .wrap-404 .error-caption {
    font-size: 120px;
  }

  .wrap-404 .error-title {
    font-size: 30px;
    line-height: 1.2;
  }

  .wrap-404 .error-subtitle {
    margin-top: 26px;
    font-size: 18px;
    line-height: 1.5;
  }

  .wrap-404 .btn-wrap {
    margin-top: 55px;
  }
}
