.staff-single-page-template {
  .content-col {
    .default-page-content {
      .staff-thumbnail {
        float: left;
        margin-right: 35px;

        img {
          height: auto;
          max-width: 240px;
          margin-right: 5%;
          padding-bottom: 20px;
        }

        @media (min-width: 660px) {
          & + * {
            margin-top: 0;
          }
        }

        @media (max-width: 660px) {
          float: none;
          img {
            max-width: 100%;
          }
        }

      }
    }
  }
}