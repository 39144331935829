.services-page-template {
  .services-grid {
    margin-top: 40px;
    margin-bottom: 40px;
  }
}

// Services Grid
.services-grid {
  display: flex;
  flex-wrap: wrap;
  margin-left: -20px;
  margin-right: -20px;

  @media (min-width: 992px) {
    &[data-col='2'] {
      .service-item {
        width: calc(100% / 2 - 40px);
      }
    }
    &[data-col='3'] {
      .service-item {
        width: calc(100% / 3 - 40px);
      }
    }
    &[data-col='4'] {
      .service-item {
        width: calc(100% / 4 - 40px);
      }
    }
  }

  .service-item {
    width: calc(100% / 3 - 40px);
    margin-left: 20px;
    margin-right: 20px;
    margin-bottom: 40px;

    &__photo {
      position: relative;
      display: block;
      width: 100%;

      &:after {
        display: block;
        content: "";
        padding-bottom: 100%;
      }

      img {
        position: absolute;
        width: 100%;
        height: 100%;
        object-fit: cover;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
      }
    }

    &__title {
      margin-top: 25px;
      margin-bottom: 20px;
      display: block;
      text-align: center;
    }

    &__description {
      display: block;
      text-align: center;
    }
  }

  // Responsive
  @media (max-width: 991px) {
    .service-item {
      width: calc(100% / 2 - 40px);
    }
  }
  @media (max-width: 660px) {
    .service-item {
      width: calc(100% - 40px);
      max-width: 300px;
      margin-left: auto;
      margin-right: auto;
    }
  }
}







