// Basic
.clearfix {
  &:before, &:after {
    content: "";
    display: table;
    clear: both;
  }
}

.overflow-hidden {
  overflow: hidden;
}

.relative {
  position: relative;
}

.aligncenter, .center {
  text-align: center;
}

.alignleft, .left {
  text-align: left;
}

.alignright, .right {
  text-align: right;
}

.mb-no {
  margin-bottom: 0 !important;
}

.mb-15 {
  margin-bottom: 15px !important;
}


.no-mt {
  margin-top: 0 !important;
}

