.content-btn, .wpforms-submit, .wpforms-form button[type=submit] {
  background: #2a639d;
  border: 2px solid #2a639d;
  color: #fff !important;
  font-size: 15px;
  font-weight: 500;
  letter-spacing: 1px;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  transition: 0.3s all ease;
  border-radius: 4px !important;
  cursor: pointer;
  padding: 15px;
}

.btn-small {
  font-size: 15px;
  padding: 8px 20px;
}

.content-btn:hover, .content-btn:active, .content-btn:focus, .wpforms-submit:hover, .wpforms-submit:active, .wpforms-submit:focus, div.wpforms-container-full .wpforms-form button[type=submit]:hover, div.wpforms-container-full .wpforms-form button[type=submit]:active {
  color: #fff;
  background-color: #bb8844;
  border: 2px solid #bb8844;
}


