:root {
  --vh: 1vh;
}


$color-brown: #bb8844;
$color-blue: #2a639d;
$color-aqua:#1FC2D8;

$font-raleway: "Raleway";
$color-grey:rgb(118, 118, 118);
$color-light-grey:#c2c2c2;

$color-white:#fff;