.pre-footer-area {
  padding: 35px;
  ul, ol {
    list-style-type: none;
  }
  li{
    list-style-type: none;
  }
}

.footer {
  .simple-social-icons ul {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-left: auto !important;
    margin-right: auto !important;
  }
}

.footer-playful {
  padding-top: 55px;
  padding-bottom: 30px;

  .footer-row {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    background-color: #fff;
    padding-top: 50px;
    box-sizing: border-box;
  }

  .footer-col {
    box-sizing: border-box;
    width: calc(100% / 3);
    padding-left: 30px;
    padding-right: 30px;
    position: relative;
    text-align: center;

    &:after {
      display: block;
      content: "";
      height: calc(100% - 50px);
      width: 1px;
      position: absolute;
      top: 0;
      bottom: 0;
      right: 0;
      background-color: #252627;
    }

    &:last-child:after {
      display: none;
    }

    .widget {
      margin-bottom: 50px;
      text-align: center;

      .widget-title {
        display: block;
        text-align: center;
        margin: 0;
        margin-bottom: 25px !important;
      }

      h1,
      h2,
      h3,
      h4,
      h5,
      h6 {
        margin: 0;
        display: block;
        margin-bottom: 20px;
      }

      .btn {
        white-space: nowrap;
        text-align: center;
        padding-left: 15px;
        padding-right: 15px;
        display: inline-block;
        margin-top: 10px;
      }

      p {
        margin-top: 0;
        margin-bottom: .6em;
        line-height: 1.35;

        &:last-child {
          margin-bottom: 0;
        }
      }

    }

  }

  .copyright-area {
    .row {
      display: flex;
      align-items: center;
      justify-content: space-between;
      color: #fff;
      margin-top: 10px;
    }

    .col-left {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      padding: 15px;
    }

    .col-right {
      display: flex;
      justify-content: flex-end;
      align-items: center;
      padding: 15px;
    }
  }

  @media (max-width: 991px) {
    .footer-row {
      padding-top: 0;
    }
    .footer-col {
      width: 100%;
      padding-left: 15px;
      padding-right: 15px;

      .widget {
        margin: 45px 0;
      }

      &:after {
        display: block;
        bottom: 0;
        width: 100%;
        right: 0;
        left: 0;
        top: auto;
        height: 1px;
      }
    }
  }
  @media (max-width: 660px) {
    .footer-col {
      padding-left: 15px;
      padding-right: 15px;
    }
  }
  @media (max-width: 400px) {
    .copyright-area .row {
      flex-direction: column;
      justify-content: center;
      align-items: center;
      text-align: center;
    }
  }
}


/****************

 Footer A

****************/

.footer-a {

  .wrap {
    padding-top: 70px;
    padding-bottom: 20px;
  }

  .row {
    justify-content: space-between;
  }

  .sidebar-col {
    box-sizing: border-box;
    flex-grow: 1;
    flex-basis: calc(100% / 3);
    padding: 0 15px;
    margin-bottom: 35px;

    .widget {
      text-align: center;
      margin-bottom: 20px;

      .widget-title {
        text-align: center;
        margin: 0;
        margin-bottom: 20px;
      }

      .textwidget p {
        margin-top: 0;
      }
    }
  }

  .copyright-bar {
    min-height: 75px;
    display: flex;
    align-items: center;
    justify-content: center;

    .copyright-bar-col {
      padding: 25px 15px;

      .widget p {
        margin: 0;
        font-size: 17px;
      }
    }
  }

  @media (max-width: 991px) {
    .row {
      flex-direction: column;
      justify-content: center;
      align-items: center;
    }
    .sidebar-col {
      width: 100%;
      flex-basis: 100%;
      text-align: center;
      max-width: 480px;
      margin-left: auto;
      margin-right: auto;
    }
    .copyright-bar {
      padding: 25px 0;

      .copyright-bar-col {
        padding: 0 15px;
        margin-bottom: 15px;

        &:last-child {
          margin-bottom: 0;
        }
      }
    }
  }
}


/****************

Custom Footer

****************/
.custom-footer[data-cols="4"] .sidebar-col {
  flex-basis: calc(100% / 4);
}

.custom-footer[data-cols="3"] .sidebar-col {
  flex-basis: calc(100% / 3);
}

.custom-footer[data-cols="2"] .sidebar-col {
  flex-basis: calc(100% / 2);
}

.custom-footer[data-cols="1"] .sidebar-col {
  flex-basis: calc(100% / 1);
}


/****************

 Footer B

****************/
.footer-denver {
  padding: 35px 0;

  .widget {
    display: flex;
    flex-direction: column;
    justify-content: center;
    text-align: center;
    list-style-type: none;
    margin-bottom: 20px;

    .btn-wrap, .footer-btn-wrap {
      display: flex;
      justify-content: center;
      align-items: center;
      flex-wrap: wrap;

      .btn {
        margin: 15px 8px;
      }
    }

    &:last-child {
      margin-bottom: 0;
    }

    &, p {
      font-size: 16px;
      color: $color-white;

      a {
        color: $color-white;
        @include transition;

        &:hover {
          color: $color-brown;
        }
      }
    }

  }
}